// export const onRouteUpdate = () => {
//   console.log('onRouteUpdate');
//   navigator.serviceWorker.register('/sw.js').then((reg) => {
//     console.log('onRouteUpdate - UPDATE');
//     reg.update();
//   });
// };
//
// export const onServiceWorkerUpdateReady = () => {
//   console.log('service worker update ready');
//   const answer = window.confirm(
//     `This application has been updated. ` +
//     `Reload to display the latest version?`
//   )
//   if (answer === true) {
//     window.location.reload(true)
//   }
// }
//
// export const onServiceWorkerUpdateFound = () => {
//   console.log('service worker update found');
// }
import Bus from "./src/Utils/Bus";

window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));